import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { maContent, enContent, poContent, buContent, SchoolChoiceContent, tuContent } from './school-choice.content-map';

export enum SchoolChoiceLanguage {
    MOROCCAN_ARABIC = 'ma',
    POLISH = 'po',
    BULGARIAN = 'bu',
    TURKISH = 'tu',
    ENGLISH = 'en'
}

const countryContentMap: { [key in SchoolChoiceLanguage]: SchoolChoiceContent } = {
    [SchoolChoiceLanguage.MOROCCAN_ARABIC]: maContent,
    [SchoolChoiceLanguage.POLISH]: poContent,
    [SchoolChoiceLanguage.BULGARIAN]: buContent,
    [SchoolChoiceLanguage.TURKISH]: tuContent,
    [SchoolChoiceLanguage.ENGLISH]: enContent
}

enum SchoolChoiceYear {
    TWO = '2',
    THREE = '3',
    FOUR = '4'
}
@Component({
    selector: 'app-school-choice',
    templateUrl: './school-choice.component.html',
    styleUrls: ['./school-choice.component.scss']
})
export class SchoolChoiceComponent implements OnInit {
    selectedYear: SchoolChoiceYear | null = null;
    selectedCountry: SchoolChoiceLanguage | null = null;
    content: SchoolChoiceContent = enContent;
    SchoolChoiceLanguage = SchoolChoiceLanguage;
    SchoolChoiceYear = SchoolChoiceYear;


    constructor(private router: Router, private route: ActivatedRoute) { }

    ngOnInit(): void {
        const urlYear = this.route.snapshot.url[0].path.slice(12, 13);
        if (Object.values(SchoolChoiceYear).includes(urlYear as SchoolChoiceYear)) {
            this.selectedYear = urlYear as SchoolChoiceYear;
        } else {
            alert('Year for display not found!');
            return;
        }
        this.selectedCountry = this.route.snapshot.params.lang;
        const langValues = Object.values(SchoolChoiceLanguage);
        if (!this.selectedCountry || !langValues.includes(this.selectedCountry)) {
            this.router.navigate(['/', `schoolkeuze-${this.selectedYear}-jarige`, SchoolChoiceLanguage.ENGLISH]);
        }
        if (this.selectedCountry) {
            this.content = countryContentMap[this.selectedCountry];
        }
    }

    switchLanguage(country: SchoolChoiceLanguage): void {
        this.selectedCountry = country;
        this.content = countryContentMap[country];
        this.router.navigate([`/schoolkeuze-${this.selectedYear}-jarige/`, this.selectedCountry]);
    }
}
