export interface SchoolChoiceContent {
    twoYear: {
        mainTitle: string;
        subTitle: string;
        choosingSchool: {
            title: string;
            description: string;
        },
        anyQuestions: {
            title: string;
            description: string;
        };
    };

    threeYear: {
        mainTitle: string;
        subTitle: string;
        enrolmentForm: {
            title: string;
            description1: string;
            description2: string;
            description3: string;
        },
        needHelp: {
            title: string;
            description1: string;
            description2: string;
        };
    };
    bottomText: string;
    bottomTextSep: string;
    figCaptionQuote: string;
    figCaptionRef: string;
    fourYear: {
        mainTitle: string;
        subTitle: string;
        enrolment: {
            title: string;
            description1: string;
            description2: string;
        };
        language: {
            title: string;
            description: string;
        };
        extraHelp: {
            title: string;
            description1: string;
            description2: string;
            description3: string;
        };
        schoolAttendance: {
            title: string;
            description1: string;
            description2: string;
            description3: string;
        };
    };
}

export const maContent: SchoolChoiceContent = {
    twoYear: {
        mainTitle: 'تهانينا – (كاد) طفلك يبلغ عامه الثاني!',
        subTitle: 'هذا وقت مناسب للبدء في التفكير بشأن أفضل مدرسة ابتدائية لطفلك. اختيار مدرسة ابتدائية عملية سهلة، وهي على النحو التالي.',
        choosingSchool: {
            title: 'اختيار مدرسة ابتدائية',
            description: 'هناك أكثر من 150 مدرسة ابتدائية في لاهاي. يمكنك العثور عليهم جميعًا من خلال الرابط التالي',
        },
        anyQuestions: {
            title: 'هل لديك أسئلة؟',
            description: 'يرجى إرسال رسالة إلكترونية إلى مكتب خدمة أولياء الأمور على العنوان التالي',
        },
    },

    threeYear: {
        mainTitle: 'تهانينا – (كاد) طفلك يبلغ عامه الثالث!',
        subTitle: 'هذا يعني أنه يجب عليك إلحاق طفلك بمدرسة ابتدائية. تسجيل طفلك في المدرسة الابتدائية عملية سهلة، وهي على النحو التالي. ',
        enrolmentForm: {
            title: 'نموذج التسجيل',
            description1: 'في هذا المظروف، ستجد نموذج التسجيل. يرجى إكمال هذا النموذج وإرساله إلى المدرسة الابتدائية التي تختارها.',
            description2: 'في لاهاي، تتاح لكل الأطفال فرص متكافئة في الحياة. ولذلك نستخدم إجراءً خاصًا بالتسجيل. ويطلق عليه <em>AanmeldenPO</em> يرجى تسجيل طفلك في أثناء فترة التسجيل الصحيحة. هذا مهم للغاية!',
            description3: 'للحصول على معلومات إضافية بخصوص هذا الإجراء، رجاءً التوجه إلى موقع الويب.'
        },
        needHelp: {
            title: 'هل تحتاج إلى مساعدة؟',
            description1: 'يمكنك الحصول على معلومات إضافية على موقع الويب',
            description2: 'إذا لم تجد الإجابة عن أسئلتك على موقع الويب المذكور، يرجى إرسال رسالة إلكترونية إلى مكتب خدمة أولياء الأمور على العنوان التالي'
        },
    },
    bottomText: 'الإعداد للمدرسة الابتدائية تُشكل خطوة الانتقال إلى المدرسة الابتدائية خطوة كبيرة بالنسبة لفئة كبيرة من الأطفال. ولهذه الشريحة من الأطفال، توجد مرحلة ما قبل المدرسة. وهي عبارة عن مكان يذهب إليه الأطفال الذين تتراوح أعمارهم بين 2.5 و4 سنوات للعب والتعلم. المزيد من المعلومات على موقع',
    bottomTextSep: '',
    figCaptionQuote: 'يستحق طفلك أفضل تعليم. اختر المدرسة الأفضل لطفلك.',
    figCaptionRef: 'هيلبرت بريديميجير، عضو المجلس المحلي للتعليم',
    fourYear: {
        mainTitle: 'هل طفلك يبلغ من العمر 4 سنوات، وهل لم يذهب إلى المدرسة بعد؟',
        subTitle: 'إذن من المهم أن تقوم بسرعة بتسجيل طفلك في المدرسة الابتدائية.',
        enrolment: {
            title: 'سجل طفلك في المدرسة',
            description1: 'لتسجيل طفلك، يرجى الاتصال بمدرسة. اسأل إذا كان هناك مكان متاح.  إن لم يكن هناك مكان متاح يرجى الاتصال بمدرسة أخرى. على الموقع',
            description2: 'يمكنك معرفة المدارس الابتدائية الموجودة.'
        },
        language: {
            title: 'اللغة',
            description: 'هل طفلك لا يتحدث الهولندية بعد؟ يمكنك أيضًا تسجيل طفلك البالغ من العمر 4 سنوات في مدرسة ابتدائية عادية. هناك أيضًا مدارس دولية في لاهاي. ستجد المزيد من المعلومات حول هذا على'
        },
        extraHelp: {
            title: 'مساعدة اضافية في المدرسة',
            description1: 'يرجى إخبار المدرسة إذا كان طفلك يحتاج إلى مساعدة إضافية. يمكن للمدرسة أن تزودك بمعلومات حول التعليم المناسب. لمزيد من المعلومات، قم بزيارة الموقع الإلكتروني لمركز دعم الآباء والشباب:',
            description2: ' أو اتصل بمركز الدعم عبر',
            description3: 'أو'
        },
        schoolAttendance: {
            title: 'إلزامية التعليم من سن 5 سنوات',
            description1: 'يرجى ملاحظة: عندما يبلغ طفلك سن الخامسة، يبدأ التعليم الإلزامي. عندئذ يجب عليك التأكد من تسجيل طفلك في المدرسة. هل لديك أسئلة حول التعليم الإلزامي؟ أو هل لا يستطيع طفلك الذهاب إلى المدرسة عندما يبلغ الخامسة من عمره؟ يرجى الاتصال بقسم التعليم الإلزامي عبر',
            description2: 'أو',
            description3: '. انظر أيضًا'
        }
    }
};

export const enContent: SchoolChoiceContent = {
    twoYear: {
        mainTitle: 'Congratulations – your child is (almost) 2!',
        subTitle: 'This is a good time to start thinking about the best primary school for your child. Choosing a primary school is easy. This is how it works.',
        choosingSchool: {
            title: 'Choosing a primary school',
            description: 'There are more than 150 primary schools in The Hague. You can find them all at',
        },
        anyQuestions: {
            title: 'Any questions?',
            description: 'Please send an email to the Parent Service Desk at',
        },
    },

    threeYear: {
        mainTitle: 'Congratulations – your child is (almost) 3!',
        subTitle: 'That means it’s time to enrol your child in primary school. Enrolling your child in a primary school is easy. This is how it works.',
        enrolmentForm: {
            title: 'Enrolment form',
            description1: 'In this envelope, you’ll find an enrolment form. Please complete this form and send it to the primary school of your first choice.',
            description2: 'In The Hague, all children have the same chances in life. That’s why we use an enrolment procedure. It’s called <em>AanmeldenPO</em>. Please enrol your child during the right enrolment period. This is very important!',
            description3: 'For more information about this procedure, please visit the website'
        },
        needHelp: {
            title: 'Need help?',
            description1: 'You can find more information on',
            description2: 'If you can’t find the answer to your question there, please send an email to the Parent Service Desk at'
        },
    },
    bottomText: 'Preparing for primary school. For a large group of children, the step up to primary school is still a big one. To help them, they can attend preschool: a place of additional playing and learning for children aged between 2.5 and 4. Go to',
    bottomTextSep: 'for more information.',
    figCaptionQuote: 'Your child deserves the best education. Choose the school that is best for your child.',
    figCaptionRef: 'Hilbert Bredemeijer, alderman for education',
    fourYear: {
        mainTitle: 'Is your child 4 years old and not attending school yet?',
        subTitle: 'Then it’s important for you to enrol your child in a primary school soon.',
        enrolment: {
            title: 'Enrol your child in a school',
            description1: 'To enrol your child, contact the school of your choice. Ask whether it still has space for your child. Is the answer no? Contact another school. Visit',
            description2: 'to see which primary schools you can choose from in The Hague.'
        },
        language: {
            title: 'Language',
            description: 'Your child doesn’t speak Dutch yet? You can still enrol your child in a mainstream primary school from the age of 4. There are also international schools in The Hague. You can find more information about them on'
        },
        extraHelp: {
            title: 'Extra help at school',
            description1: 'Let the school know if your child needs extra help. The school can give you information about appropriate education. For more information, visit the Ouder- en Jeugdsteunpunt (parent and youth support centre) website:',
            description2: 'Or contact the support centre at',
            description3: 'or on'
        },
        schoolAttendance: {
            title: 'School attendance compulsory from the age of 5 ',
            description1: 'Please note: school attendance is compulsory when your child turns 5. So, you must make sure your child is enrolled in a school at this age. Do you have any questions about compulsory school attendance? Or is your child not able start school when he or she turns 5? Contact the afdeling Leerplicht (compulsory school attendance department) at',
            description2: 'or on',
            description3: 'Also see',
        }
    }
};

export const poContent: SchoolChoiceContent = {
    twoYear: {
        mainTitle: 'Hurra! Twoje dziecko ma już (prawie) 2 lata!',
        subTitle: 'To dobry moment, aby znaleźć dla niego szkołę podstawową, która będzie dla niego najodpowiedniejsza! Jak wybrać szkołę podstawową dla swojego dziecka?',
        choosingSchool: {
            title: 'Wybór szkoły podstawowej',
            description: 'W Hadze działa ponad 150 szkół podstawowych. Ich listę znajdziesz na stronie:',
        },
        anyQuestions: {
            title: 'Masz dalsze pytania?',
            description: 'Napisz e-mail do Biura Informacji dla Rodziców pod adres',
        },
    },

    threeYear: {
        mainTitle: 'Hurra! Twoje dziecko ma już (prawie) 3 lata!',
        subTitle: 'A więc przyszedł czas, by zapisać je do szkoły podstawowej. Jak zapisać dziecko do szkoły podstawowej?',
        enrolmentForm: {
            title: 'Formularz zgłoszenia',
            description1: 'W kopercie znajdziesz formularz, który służy do zgłoszenia dziecka do szkoły. Wypełnij go i przekaż do szkoły podstawowej pierwszego wyboru.',
            description2: 'Aby wszystkie dzieci w Hadze miały równe szanse na dostanie się do wybranej szkoły, korzystamy z procedury zgłoszeniowej <em>AanmeldenPO</em>. To bardzo ważne, by zgłosić swoje dziecko w odpowiednim okresie zgłoszeniowym.',
            description3: 'Więcej informacji o procedurze zgłoszeniowej znajdziesz na stronie'
        },
        needHelp: {
            title: 'Potrzebujesz pomocy?',
            description1: 'Potrzebne informacje znajdziesz na stronie',
            description2: 'Jeśli nie znajdziesz tam odpowiedzi na swoje pytanie, napisz e-mail do Biura Informacji dla Rodziców pod adres'
        },
    },
    bottomText: 'Przygotowanie do szkoły podstawowej. Dla dużej grupy dzieci przejście do szkoły podstawowej to wciąż duży krok. Dla nich jest przedszkole z programem edukacji wczesnoszkolnej. Miejsce, w którym dzieci w wieku od 2,5 do 4 lat uczą się i bawią. Więcej informacji znajduje się na stronie',
    bottomTextSep: '.',
    figCaptionQuote: 'Twoje dziecko zasługuje na najlepszą edukację. Wybierz szkołę, która będzie dla niego najodpowiedniejsza.',
    figCaptionRef: 'Radny ds. edukacji, Hilbert Bredemeijer',
    fourYear: {
        mainTitle: 'Czy Twoje dziecko ma 4 lata i nie chodzi jeszcze do szkoły?',
        subTitle: 'Jeśli tak, szybko zapisz je do szkoły podstawowej.',
        enrolment: {
            title: 'Zapisz dziecko do podstawówki',
            description1: 'Aby zapisać dziecko, należy skontaktować się ze szkołą. Zapytaj, czy są jeszcze wolne miejsca. Nie ma miejsc? W takiej sytuacji skontaktuj się z inną szkołą. Na stronie',
            description2: 'znajdziesz wykaz szkół podstawowych.'
        },
        language: {
            title: 'Język',
            description: 'Twoje dziecko nie mówi jeszcze po niderlandzku? Nic nie szkodzi! Nadal możesz zapisać swoje 4-letnie dziecko do zwykłej szkoły podstawowej. W Hadze znajdują się również szkoły międzynarodowe. Więcej informacji znajdziesz na stronie'
        },
        extraHelp: {
            title: 'Dodatkowa pomoc w szkole ',
            description1: 'Poinformuj szkołę, jeśli Twoje dziecko potrzebuje dodatkowej pomocy. Szkoła udzieli Ci informacji na temat edukacji dostosowanej do potrzeb Twojego dziecka. Więcej informacji znajdziesz na stronie internetowej Ouder- en Jeugdsteunpunt [Centrum Wsparcia Rodziców i Młodzieży]:',
            description2: 'Możesz też skontaktować się z centrum wsparcia pod adresem',
            description3: 'albo pod numerem telefonu '
        },
        schoolAttendance: {
            title: 'Obowiązek szkolny od 5 roku życia',
            description1: 'Pamiętaj, że każde dziecko, które ukończy 5 lat, zostaje objęte obowiązkiem szkolnym. W takiej sytuacji musisz upewnić się, że Twoje dziecko jest zapisane do szkoły. Masz pytania dotyczące obowiązku szkolnego? A może Twoje dziecko nie będzie mogło pójść do szkoły, gdy skończy 5 lat? Skontaktuj się z działem ds. obowiązku szkolnego pod adresem',
            description2: 'albo pod numerem telefonu',
            description3: 'Sprawdź również informacje na ',
        }
    }
};

export const buContent: SchoolChoiceContent = {
    twoYear: {
        mainTitle: 'Вашето дете е (почти) на 2 години.',
        subTitle: 'Това е подходящ момент да помислите кое е най-доброто училище за вашето дете!',
        choosingSchool: {
            title: 'Избор на начално училище',
            description: 'В Хага има повече от 150 начални училища. Всички начални училища са показани на:',
        },
        anyQuestions: {
            title: 'Въпроси?',
            description: 'Моля, свържете се с Информационната система за обслужване на родители чрез',
        },
    },

    threeYear: {
        mainTitle: 'Вашето дете е (почти) на 3 години.',
        subTitle: 'Това означава, че трябва да регистрирате детето си в някое начално училище.',
        enrolmentForm: {
            title: 'Формуляр за кандидатстване',
            description1: 'В този плик ще намерите формуляр за регистриране. Трябва да попълнете този формуляр и да го предадете в началното училище, което сте избрали като първо.',
            description2: 'Всяко дете в Хага има равни възможности. Ето защо работим с процедура на регистрация: <em>AanmeldenPO</em>. Регистрирайте детето си в правилния период на регистриране! Това е много важно!',
            description3: 'Желаете ли да научите повече за тази процедура? Посетете уебсайта '
        },
        needHelp: {
            title: 'Необходима ви е помощ?',
            description1: 'Повече информация ще намерите на',
            description2: 'Не получихте ли отговор на въпроса ви? Свържете се с Информационната система за обслужване на родители чрез'
        },
    },
    bottomText: 'За много деца стъпката към началното училище все още е голяма. За тях има предучилищна възраст. Място, където децата на възраст между 2,5 и 4 години допълнително играят учат.',
    bottomTextSep: 'Повече информация на',
    figCaptionQuote: 'Вашето дете заслужава най-доброто образование. Изберете училище, което е най-добро за вашето дете',
    figCaptionRef: 'ъветник по Образованието, Хилберт Бредемайер',
    fourYear: {
        mainTitle: 'Ако Вашето дете е на 4 години и все още не ходи на училище,',
        subTitle: 'е важно в най-скоро време да запишете детето си в начално училище.',
        enrolment: {
            title: 'Запишете детето си в училище',
            description1: 'Свържете се с училището, за да запишете детето си. Попитайте дали има още място. Ако няма място, свържете се с друго училище. На',
            description2: 'можете да намерите списък на основните училища на уебсайта'
        },
        language: {
            title: 'Език',
            description: 'Ако Вашето дете все още не говори нидерландски, можете да запишете 4-годишното си дете в обикновено начално училище. В Хага има и международни училища. На уебсайта  ще намерите повече информация за това.'
        },
        extraHelp: {
            title: 'Допълнителна помощ в училище',
            description1: 'Уведомете училището, ако детето Ви се нуждае от допълнителна помощ. Училището може да Ви даде информация за подходящо обучение. За повече информация посетете уебсайта на Центъра за подкрепа на родители и деца и юноши „Ouder- en Jeugdsteunpunt“:',
            description2: 'Можете също да се свържете с този центъра за подкрепа на адрес на електронна поща',
            description3: 'или на телефон'
        },
        schoolAttendance: {
            title: 'Задължително образование от 5-годишна възраст',
            description1: 'Моля, обърнете внимание: когато детето Ви навърши 5 години, то подлежи на  задължителното образование. Тогава трябва да се погрижите детето Ви да е записано в училище. Ако имате въпроси относно задължителното образование или детето Ви не може да посещава училище, когато навърши 5 години, моля, свържете се с отдела за задължително образование „Leerplicht“ на адрес на електронна поща',
            description2: 'или на телефон',
            description3: 'Посетете също уебсайта ',
        }
    }
};

export const tuContent: SchoolChoiceContent = {
    twoYear: {
        mainTitle: 'Yaşasın, çocuğunuz (neredeyse) 2 yaşında!',
        subTitle: 'Hangi okulun çocuğunuza uygun olduğunu değerlendirmek için iyi bir zaman! Bir ilköğretim okulu seçmek: bu husus nasıl geçekleşir?',
        choosingSchool: {
            title: 'Bir ilköğretim okulu seçimi',
            description: 'Den Haag\'ta 150\'den fazla ilköğretim okulu vardır. Tüm ilköğretim okullarının listelenmiş olduğu web sitesi adresi şudur',
        },
        anyQuestions: {
            title: 'Sorularınız mı var?',
            description: 'O halde <a href="mailto:ouders@aanmeldenpo.nl">ouders@aanmeldenpo.nl</a> e-postası aracılığıyla Ouder Servicedesk (Ebeveyn Hizmet Masası) ile iletişime geçin.',
        },
    },

    threeYear: {
        mainTitle: 'Yaşasın, çocuğunuz (neredeyse) 3 yaşında!',
        subTitle: 'Bu durum, çocuğunuzu bir ilköğretim okuluna kaydetmeniz gerektiği anlamına gelir. Bir ilköğretim okuluna kaydetmek: işte bu şekilde gerçekleşir!',
        enrolmentForm: {
            title: 'Kayıt formu',
            description1: 'Bu zarfın içinde bir kayıt formu bulacaksınız. Bu formu doldurmalı ve ilk tercihiniz olan ilköğretim okuluna teslim etmelisiniz.',
            description2: 'Den Haag&apos;taki her çocuk eşit fırsatlara sahiptir. Bu nedenle bir kayıt prosedürü (süreci) ile çalışıyoruz: <em>AanmeldenPO</em>. Çocuğunuzu doğru kayıt döneminde kaydedin. Bu çok önemlidir! ',
            description3: 'Bu prosedür hakkında daha fazla bilgi edinmek ister misiniz? Şu adresli web sitesini ziyaret ediniz'
        },
        needHelp: {
            title: 'Yardıma ihtiyacınız mı var?',
            description1: 'Daha fazla bilgiyi <a href="https://scholenwijzer.denhaag.nl/">scholenwijzer.denhaag.nl</a> adresinde bulabilirsiniz.',
            description2: 'Sorunuz cevaplanmadı mı? O halde <a href="mailto:ouders@aanmeldenpo.nl">ouders@aanmeldenpo.nl</a> e-postası aracılığıyla Ouder Servicedesk (Ebeveyn Hizmet Masası) ile iletişime geçin.'
        },
    },
    bottomText: 'İlkokula hazırlık. Çocukların büyük bir kısmı için ilkokula adım hala büyük bir adımdır. Bu çocuklar için anaokulu var. 2,5 - 4 yaş arası çocukların oynayabileceği ve daha fazlasını öğrenebileceği bir yer. Daha fazla bilgi ',
    bottomTextSep: ' adresinde.',
    figCaptionQuote: 'Çocuğunuz en iyi eğitimi hak ediyor. Çocuğunuza uygun olan okulu seçin.',
    figCaptionRef: 'Eğitimle ilgili belediye meclis üyesi, Hilbert Bredemeijer',
    fourYear: {
        mainTitle: 'Çocuğunuz 4 yaşında ve henüz okula gitmiyor mu?',
        subTitle: 'Bu durumda çocuğunuzu en kısa süre içinde ilkokula kaydettirmeniz önem arz etmektedir.',
        enrolment: {
            title: 'Çocuğunuzu bir okula kaydettirin',
            description1: 'Çocuğunuzu kaydettirmek için lütfen bir okulla iletişime geçiniz. Okula, kayıt için kontenjan olup olmadığını sorun. Kontenjan yok mu?  Bu durumda başka bir okulla iletişime geçiniz.',
            description2: 'internet sayfasında hangi ilkokulların mevcut olduğunu öğrenebilirsiniz.'
        },
        language: {
            title: 'Dil',
            description: 'Çocuğunuz henüz Hollandaca konuşamıyor mu? Böyle bir durumda bile 4 yaşındaki çocuğunuzu normal bir ilkokula da kaydettirebilirsiniz. Lahey\'de uluslararası okullar da bulunmaktadır. Bu konuda daha fazla bilgiyi sayfasından  erişebilirsiniz. '
        },
        extraHelp: {
            title: 'Okulda ekstra destek',
            description1: 'Çocuğunuzun ekstra desteğe ihtiyacı varsa bunu okula bildirin. Okul size uyarlanmış eğitim hakkında bilgi verecektir. Daha fazla bilgi için “Ouder- en Jeugdsteunpunt” kurumunun internet sitesi olan:',
            description2: 'Veya e-posta adresine e-posta gönderebilir',
            description3: 'veya numarayı arayarak destek merkeziyle iletişime geçebilirsiniz.'
        },
        schoolAttendance: {
            title: '5 yaşından itibaren zorunlu eğitim',
            description1: 'Dikkat: Çocuğunuz 5 yaşını doldurduğu anda zorunlu eğitime tabii olur.  Bu nedenle çocuğunuzu mutlaka bir okula kaydettirmelisiniz. Zorunlu eğitimle ilgili sorularınız mı var? Veya çocuğunuz 5 yaşını doldurduğu halde okula gidebilecek durumda değil mi?  Öyleyse Zorunlu Eğitim departmanıyla',
            description2: 'veya üzerinde',
            description3: 'Ayrıca bakınız',
        }
    }
};
