<main class="main-content">
    <article class="st-inner">
        <nav class="container" [class.arabic]="selectedCountry === SchoolChoiceLanguage.MOROCCAN_ARABIC">
            <ul class="flag-list">
                <li *ngFor="let country of SchoolChoiceLanguage | keyvalue" class="flag-list-item" (click)="switchLanguage(country.value)">
                    <img src="assets/img/school-choice/flag_{{country.value}}.png" alt="{{country.value}}">
                </li>
            </ul>
        </nav>
        
        
        <div class="container container-2-year" [class.arabic]="selectedCountry === SchoolChoiceLanguage.MOROCCAN_ARABIC" *ngIf="selectedYear === SchoolChoiceYear.TWO">
            <header>
                <h3 class="title">{{content.twoYear.mainTitle}}</h3>
                <h4 class="subtitle">{{content.twoYear.subTitle}}</h4>
            </header>
            <article class="2-year">
               <section class="choosing-school">
                    <h5 class="par-title">{{content.twoYear.choosingSchool.title}}</h5>
                    <p class="par-content">{{content.twoYear.choosingSchool.description}} <a href="https://scholenwijzer.denhaag.nl/juisteschoolkiezen" target="_blank">scholenwijzer.denhaag.nl/juisteschoolkiezen</a>.</p>
               </section>
               <section class="any-questions">
                    <h5 class="par-title">{{content.twoYear.anyQuestions.title}}</h5>
                        
                    <p class="par-content" *ngIf="selectedCountry !== SchoolChoiceLanguage.TURKISH">
                        {{content.twoYear.anyQuestions.description}}
                        <a href="mailto:ouders@aanmeldenpo.nl">ouders@aanmeldenpo.nl</a>.
                    </p>
                    <p class="par-content" *ngIf="selectedCountry === SchoolChoiceLanguage.TURKISH" [innerHTML]="content.twoYear.anyQuestions.description"></p>
               </section>
            </article>
        </div>

        <article class="container container-3-year" [class.arabic]="selectedCountry === SchoolChoiceLanguage.MOROCCAN_ARABIC" *ngIf="selectedYear === SchoolChoiceYear.THREE">
            <header>
                <h3 class="title">{{content.threeYear.mainTitle}}</h3>
                <h4 class="subtitle">{{content.threeYear.subTitle}}</h4>
            </header>
            <section class="3-year">
                <section class="enrolment">
                    <h5 class="par-title">{{content.threeYear.enrolmentForm.title}}</h5>
                    <p class="par-content">{{content.threeYear.enrolmentForm.description1}}</p>
                    <p class="par-content" [innerHTML]="content.threeYear.enrolmentForm.description2"></p>
                    <p class="par-content">
                        {{content.threeYear.enrolmentForm.description3}}
                        <a href="https://scholenwijzer.denhaag.nl/aanmeldenbasisschool">scholenwijzer.denhaag.nl/aanmeldenbasisschool</a>.
                    </p>
                </section>
                <section class="need-help" *ngIf="selectedCountry !== SchoolChoiceLanguage.TURKISH">
                    <h5 class="par-title">{{content.threeYear.needHelp.title}}</h5>
                    <p class="par-content">
                        {{content.threeYear.needHelp.description1}}
                        <a href="https://scholenwijzer.denhaag.nl/">scholenwijzer.denhaag.nl</a>.
                    </p>
                    <p class="par-content">
                        {{content.threeYear.needHelp.description2}}
                        <a href="mailto:ouders@aanmeldenpo.nl">ouders@aanmeldenpo.nl</a>.
                    </p>
                </section>
                <section class="need-help" *ngIf="selectedCountry === SchoolChoiceLanguage.TURKISH">
                    <h5 class="par-title">{{content.threeYear.needHelp.title}}</h5>
                    <p class="par-content" [innerHTML]="content.threeYear.needHelp.description1"></p>
                    <p class="par-content" [innerHTML]="content.threeYear.needHelp.description2"></p>
                </section>

            </section>
        </article>

        <article class="container container-4-year" [class.arabic]="selectedCountry === SchoolChoiceLanguage.MOROCCAN_ARABIC" *ngIf="selectedYear === SchoolChoiceYear.FOUR">
            <header>
                <h3 class="title">{{content.fourYear.mainTitle}}</h3>
                <h4 class="subtitle">{{content.fourYear.subTitle}}</h4>
            </header>
            <section class="4-year">
                <section class="enrolment">
                    <h5 class="par-title">{{ content.fourYear.enrolment.title }}</h5>
                    <p class="par-content">{{ content.fourYear.enrolment.description1 }} <a href="https://scholenwijzer.denhaag.nl">scholenwijzer.denhaag.nl</a> {{ content.fourYear.enrolment.description2 }}</p>
                </section>
                <section class="language">
                    <h5 class="par-title">{{ content.fourYear.language.title }}</h5>
                    <p class="par-content">{{ content.fourYear.language.description }} <a href="https://scholenwijzer.denhaag.nl">scholenwijzer.denhaag.nl</a>.</p>
                </section>
                <section class="extra-help">
                    <h5 class="par-title">{{ content.fourYear.extraHelp.title }}</h5>
                    <p class="par-content">{{ content.fourYear.extraHelp.description1 }} <a href="https://ojsp-haaglanden.nl">www.ojsp-haaglanden.nl</a>. {{ content.fourYear.extraHelp.description2 }}
                        <a href="mailto:info@ojsp-haaglanden.nl">info@ojsp-haaglanden.nl</a>  {{ content.fourYear.extraHelp.description3 }}
                        <a href="tel:070-3156356">070 - 31 56 356</a>.</p>
                </section>
                <section class="school-attendance">
                    <h5 class="par-title">{{ content.fourYear.schoolAttendance.title }}</h5>
                    <p class="par-content">{{ content.fourYear.schoolAttendance.description1 }} <a href="mailto:leerplicht@denhaag.nl">leerplicht@denhaag.nl</a> {{ content.fourYear.schoolAttendance.description2 }}
                        <a href="tel:070-3535454">070 - 353 54 54</a>. {{ content.fourYear.schoolAttendance.description3 }}
                        <a href="https://denhaag.nl/leerplicht">www.denhaag.nl/leerplicht</a> </p>
                </section>
            </section>
        </article>

        <footer class="container" [class.arabic]="selectedCountry === SchoolChoiceLanguage.MOROCCAN_ARABIC" *ngIf="selectedYear !== SchoolChoiceYear.FOUR">
            <p class="bottom-text">
                {{content.bottomText}}
                <a href="https://www.vraagjufmina.nl/" target="_blank">www.vraagjufmina.nl</a> {{content.bottomTextSep}}
            </p>
        </footer>

        <aside class="img-container" [class.arabic]="selectedCountry === SchoolChoiceLanguage.MOROCCAN_ARABIC">
            <img src="assets/img/school-choice/hilbert.jpeg" alt="hilbert_bredemeijer">
            <figcaption>
                <h5 class="figCaption-quote">"{{content.figCaptionQuote}}"</h5>
                <p class="figCaption-ref">{{content.figCaptionRef}}</p>
            </figcaption>
        </aside>

        <div class="banner">
            <img src="assets/img/school-choice/banner-{{selectedYear}}.png" alt="">
        </div>

    </article>
</main>
