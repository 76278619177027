<main class="main-content">
    <article class="st-inner">

        <div class="figure-1"></div>
        <div class="figure-2"></div>
        <div class="figure-3"></div>
        <div class="figure-4"></div>
        <div class="figure-5"></div>

        <div class="container">
            <header>
                <h3>{{'NEWCOMER_SIGNUP.HEADER' | translate}}</h3>
                <p>{{'NEWCOMER_SIGNUP.BODY1' | translate}}</p>
                <p>{{'NEWCOMER_SIGNUP.BODY2' | translate}}</p>
                <p>{{'NEWCOMER_SIGNUP.BODY3' | translate}}</p>
                <p>{{'NEWCOMER_SIGNUP.BODY4' | translate}}</p>
            </header>

            <form [formGroup]="questionnaire" (ngSubmit)="onSubmit()" class="form">

                <h4>{{'DETAILS_CHILD' | translate}}</h4>

                <fieldset>
                    <div class="form-group">
                        <label for="age_category">{{'AGE_CATEGORY' | translate}}*</label>
                        <select formControlName="age_category" id="age_category">
                            <option [ngValue]="null" default selected disabled>-- {{'PICK_AN_OPTION' | translate}} --</option>
                            <option [value]="AgeCategory.PO">{{'NEWCOMER_SIGNUP.4to11' | translate}}</option>
                            <option [value]="AgeCategory.VO">{{'NEWCOMER_SIGNUP.12to18' | translate}}</option>
                        </select>
                        <p class="required-warning" *ngIf="isFieldInvalid('age_category')">{{'REQUIRED_FIELD' | translate}}</p>
                    </div>
                </fieldset>

                <fieldset *ngIf="!showIskRedirect">
                    <div class="form-group">
                        <label for="first_name">{{'FIRST_NAME_CHILD' | translate}}*</label>
                        <input type="text" class="form-control" id="first_name" formControlName="first_name" />
                        <p class="required-warning" *ngIf="isFieldInvalid('first_name')">{{'REQUIRED_FIELD' | translate}}</p>
                    </div>
                    <div class="form-group">
                        <label for="last_name">{{'LAST_NAME_CHILD' | translate}}*</label>
                        <input type="text" class="form-control" id="last_name" formControlName="last_name" />
                        <p class="required-warning" *ngIf="isFieldInvalid('last_name')">{{'REQUIRED_FIELD' | translate}}</p>
                    </div>
                    <div class="form-group">
                        <label for="date_of_birth">{{'DATE_OF_BIRTH' | translate}}*</label>
                        <input type="date" class="form-control" id="date_of_birth" formControlName="date_of_birth" />
                        <p class="required-warning" *ngIf="isFieldInvalid('date_of_birth')">{{'REQUIRED_FIELD' | translate}}</p>
                    </div>
                    <div class="form-group">
                        <label for="sex">{{'SEX' | translate}}*</label>
                        <select formControlName="sex" id="sex">
                            <option [ngValue]="null" default selected disabled>-- {{'PICK_AN_OPTION' | translate}} --</option>
                            <option [value]="Sex.GIRL">{{'GIRL' | translate}}</option>
                            <option [value]="Sex.BOY">{{'BOY' | translate}}</option>
                            <option [value]="Sex.OTHER">{{'OTHER_PREFER_NOT_TO_SAY' | translate}}</option>
                        </select>
                        <p class="required-warning" *ngIf="isFieldInvalid('sex')">{{'REQUIRED_FIELD' | translate}}</p>
                    </div>
                    <div class="form-group">
                        <label for="already_in_netherlands">{{'ALREADY_IN_NETHERLANDS' | translate}}*</label>
                        <select formControlName="already_in_netherlands" id="already_in_netherlands">
                            <option [ngValue]="null" default selected disabled>-- {{'PICK_AN_OPTION' | translate}} --</option>
                            <option [ngValue]="true">{{'YES' | translate}}</option>
                            <option [ngValue]="false">{{'NO' | translate}}</option>
                        </select>
                        <p class="required-warning" *ngIf="isFieldInvalid('already_in_netherlands')">{{'REQUIRED_FIELD' | translate}}</p>
                    </div>
                    <div class="form-group" *ngIf="questionnaire.get('already_in_netherlands')?.value === false">
                        <label for="date_of_birth">{{'DATE_IN_NETHERLANDS' | translate}}*</label>
                        <input type="date" class="form-control" id="arrival_date" formControlName="arrival_date" />
                        <p class="required-warning" *ngIf="isFieldInvalid('arrival_date')">{{'REQUIRED_FIELD' | translate}}</p>
                    </div>
                </fieldset>

                <h4 *ngIf="!showIskRedirect">{{'DOES_YOUR_CHILD_NEED_EXTRA_SUPPORT' | translate}}</h4>
                <div class="flex-wrapper" *ngIf="!showIskRedirect">
                    <div class="flex-item">
                        <label for="disability_support">{{'DISABILITY_SUPPORT' | translate}}*</label>
                        <p-multiSelect id="disability_support" [options]="disabilitySupportOptionsArray" [placeholder]="'-- ' + ('PICK_AN_OPTION' | translate) + ' --'" formControlName="disability_support"></p-multiSelect>
                        <p class="required-warning" *ngIf="isFieldInvalid('disability_support')">{{'REQUIRED_FIELD' | translate}}</p>
                    </div>
                    <div class="flex-item" *ngIf="!questionnaire.get('disability_support')?.value.includes('NONE')">
                        <label *ngIf="!questionnaire.get('disability_support')?.value.includes('OTHER')" for="disability_support_explanation_note">{{'EXPLANATION_NOTE' | translate}}</label>
                        <label *ngIf="questionnaire.get('disability_support')?.value.includes('OTHER')" for="disability_support_explanation_note">{{'EXPLANATION_NOTE' | translate}}*</label>
                        <input type="text" class="form-control" id="disability_support_explanation_note" formControlName="disability_support_explanation_note">
                        <p class="required-warning" *ngIf="isFieldInvalid('disability_support_explanation_note')">{{'REQUIRED_FIELD' | translate}}</p>
                    </div>
                </div>

                <h4 *ngIf="!showIskRedirect">{{'DETAILS_PARENT' | translate}}</h4>

                <fieldset *ngIf="!showIskRedirect">
                    <div class="form-group">
                        <label for="parent_first_name">{{'FIRST_NAME_PARENT' | translate}}*</label>
                        <input type="text" class="form-control" id="parent_first_name" formControlName="parent_first_name">
                        <p class="required-warning" *ngIf="isFieldInvalid('parent_first_name')">{{'REQUIRED_FIELD' | translate}}</p>
                    </div>
                    <div class="form-group">
                        <label for="parent_last_name">{{'LAST_NAME_PARENT' | translate}}*</label>
                        <input type="text" class="form-control" id="parent_last_name" formControlName="parent_last_name">
                        <p class="required-warning" *ngIf="isFieldInvalid('parent_last_name')">{{'REQUIRED_FIELD' | translate}}</p>
                    </div>
                    <div class="form-group">
                        <label for="parent_email">{{'EMAIL_PARENT' | translate}}*</label>
                        <input type="email" class="form-control" id="parent_email" formControlName="parent_email">
                        <p class="required-warning" *ngIf="isEmailInvalid('parent_email') || isFieldInvalid('parent_email')">{{getValidatorMessages('parent_email')}}</p>
                    </div>
                    <div class="form-group">
                        <label for="parent_phone">{{'PHONE_PARENT' | translate}}*</label>
                        <input type="text" class="form-control" id="parent_phone" formControlName="parent_phone_number">
                        <p class="required-warning" *ngIf="isFieldInvalid('parent_phone_number')">{{'REQUIRED_FIELD' | translate}}</p>
                    </div>
                </fieldset>

                <h4 *ngIf="!showIskRedirect">{{'DETAILS_CONTACTPERSON' | translate}}</h4>

                <fieldset *ngIf="!showIskRedirect">
                    <div class="form-group">
                        <label for="contact_first_name">{{'FIRST_NAME_CONTACTPERSON' | translate}}</label>
                        <input type="text" class="form-control" id="contact_first_name" formControlName="contact_first_name">
                    </div>
                    <div class="form-group">
                        <label for="contact_last_name">{{'LAST_NAME_CONTACTPERSON' | translate}}</label>
                        <input type="text" class="form-control" id="contact_last_name" formControlName="contact_last_name">
                    </div>
                    <div class="form-group">
                        <label for="contact_email">{{'EMAIL_CONTACTPERSON' | translate}}</label>
                        <input type="email" class="form-control" id="contact_email" formControlName="contact_email">
                        <p class="required-warning" *ngIf="isEmailInvalid('contact_email') || isFieldInvalid('contact_email')">{{getValidatorMessages('contact_email')}}</p>
                    </div>
                    <div class="form-group">
                        <label for="contact_phone_number">{{'PHONE_CONTACTPERSON' | translate}}</label>
                        <input type="text" class="form-control" id="contact_phone_number" formControlName="contact_phone_number">
                    </div>
                </fieldset>

                <h4 *ngIf="!showIskRedirect">{{'CURRENT_RESIDENCE' | translate}}</h4>

                <fieldset *ngIf="!showIskRedirect">
                    <div class="form-group">
                        <label for="postal_code">{{'POSTCODE' | translate}}*</label>
                        <input type="text" class="form-control" id="postal_code" formControlName="postal_code">
                        <p class="required-warning" *ngIf="isFieldInvalid('postal_code')">{{ getValidatorMessages('postal_code') }}</p>
                    </div>
                    <div class="form-group">
                        <label for="house_number">{{'HOUSENUMBER' | translate}}*</label>
                        <input type="text" class="form-control" id="house_number" formControlName="house_number">
                        <p class="required-warning" *ngIf="isFieldInvalid('house_number')">{{ getValidatorMessages('house_number') }}</p>
                    </div>
                    <div class="form-group">
                        <label for="street_name">{{'STREET' | translate}}*</label>
                        <input type="text" class="form-control" id="street_name" formControlName="street_name">
                        <p class="required-warning" *ngIf="isFieldInvalid('street_name')">{{'REQUIRED_FIELD' | translate}}</p>
                    </div>
                    <div class="form-group">
                        <label for="city">{{'PLACE' | translate}}*</label>
                        <input type="text" class="form-control" id="city" formControlName="city">
                        <p class="required-warning" *ngIf="isFieldInvalid('city')">{{'REQUIRED_FIELD' | translate}}</p>
                    </div>
                </fieldset>

                <fieldset *ngIf="!showIskRedirect">
                    <div class="form-group">
                        <label for="residence_change">{{'NEWCOMER_SIGNUP.CHANGE_RESIDENCE' | translate}}*</label>
                        <select formControlName="residence_change" id="residence_change">
                            <option [ngValue]="null" default selected disabled>-- {{'PICK_AN_OPTION' | translate}} --</option>
                            <option [value]="BinaryChoice.YES">{{'YES' | translate}}</option>
                            <option [value]="BinaryChoice.NO">{{'NO' | translate}}</option>
                        </select>
                        <p class="required-warning" *ngIf="isFieldInvalid('residence_change')">{{'REQUIRED_FIELD' | translate}}</p>
                    </div>
                </fieldset>

                <h4 *ngIf="residenceChange && !showIskRedirect">{{'NEW_RESIDENCE' | translate}}</h4>

                <fieldset *ngIf="residenceChange && !showIskRedirect">
                    <div class="form-group">
                        <label for="new_postal_code">{{'NEW_POSTCODE' | translate}}</label>
                        <input type="text" class="form-control" id="new_postal_code" formControlName="new_postal_code">
                        <p class="required-warning" *ngIf="isFieldInvalid('new_postal_code')">{{ getValidatorMessages('new_postal_code') }}</p>
                    </div>
                    <div class="form-group">
                        <label for="new_house_number">{{'NEW_HOUSENUMBER' | translate}}</label>
                        <input type="text" class="form-control" id="new_house_number" formControlName="new_house_number">
                        <p class="required-warning" *ngIf="isFieldInvalid('new_house_number')">{{ getValidatorMessages('new_house_number') }}</p>
                    </div>
                    <div class="form-group">
                        <label for="new_street_name">{{'NEW_STREET' | translate}}</label>
                        <input type="text" class="form-control" id="new_street_name" formControlName="new_street_name">
                        <p class="required-warning" *ngIf="isFieldInvalid('new_street_name')">{{'REQUIRED_FIELD' | translate}}</p>
                    </div>
                    <div class="form-group">
                        <label for="new_city">{{'NEW_PLACE' | translate}}</label>
                        <input type="text" class="form-control" id="new_city" formControlName="new_city">
                        <p class="required-warning" *ngIf="isFieldInvalid('new_city')">{{'REQUIRED_FIELD' | translate}}</p>
                    </div>
                </fieldset>

                <h4 *ngIf="!showIskRedirect">{{'LANGUAGE' | translate}}</h4>

                <fieldset *ngIf="!showIskRedirect">
                    <div class="form-group">
                        <label class="label-language">{{'NEWCOMER_SIGNUP.WHAT_LANGUAGE' | translate}}*</label>
                        <div class="checkbox-group-wrapper" (click)="onSelectSpokenLanguage($event)">
                            <div class="checkbox-group">
                                <label for="lang_en">{{'ENGLISH' | translate}}</label>
                                <input
                                        type="checkbox"
                                        name="lang_en"
                                        id="lang_en"
                                        value="English">
                            </div>
                            <div class="checkbox-group">
                                <label for="lang_nl">{{'DUTCH' | translate}}</label>
                                <input
                                        type="checkbox"
                                        name="lang_nl"
                                        id="lang_nl"
                                        value="Dutch">
                            </div>
                            <div class="checkbox-group">
                                <label for="lang_other">{{'OTHER' | translate}}</label>
                                <input
                                        type="checkbox"
                                        name="lang_other"
                                        id="lang_other"
                                        value="other">
                            </div>
                        </div>
                        <p class="required-warning" *ngIf="isFieldInvalid('language')">{{'REQUIRED_FIELD' | translate}}</p>
                    </div>

                    <div class="form-group interpreter">
                        <label for="interpreter-available">{{'NEWCOMER_SIGNUP.INTERPRETER_AVAILABLE' | translate}}*</label>
                        <select formControlName="interpreter_available" id="interpreter-available">
                            <option [ngValue]="null" default selected disabled>-- {{'PICK_AN_OPTION' | translate}} --</option>
                            <option [value]="BinaryChoice.YES">{{'YES' | translate}}</option>
                            <option [value]="BinaryChoice.NO">{{'NO' | translate}}</option>
                        </select>
                        <p class="required-warning" *ngIf="isFieldInvalid('interpreter_available')">{{'REQUIRED_FIELD' | translate}}</p>
                    </div>
                </fieldset>

                <fieldset *ngIf="selectedSpokenLanguages.has('other') && !showIskRedirect">
                    <div class="form-group wide">
                        <label for="other-language">{{'OTHER_LANGUAGES' | translate}}*</label>
                        <input type="text" class="form-control" id="other-language" formControlName="language_other">
                        <p class="required-warning" *ngIf="isFieldInvalid('language_other')">{{'REQUIRED_FIELD' | translate}}</p>
                    </div>
                </fieldset>

                <fieldset *ngIf="!showIskRedirect">
                    <div class="form-group wide remarks">
                        <label for="other_remarks">{{'OTHER_REMARKS' | translate}}</label>
                        <textarea formControlName="other_remarks" id="other_remarks" rows="5"></textarea>
                    </div>

                    <div class="wide remarks-disclaimer">{{'NEWCOMER_SIGNUP.REMARKS_DISCLAIMER' | translate}}</div>
                </fieldset>

                <fieldset *ngIf="!showIskRedirect">
                    <div class="form-group wide">
                        <re-captcha formControlName="captcha" size="normal" theme="light" type="image" badge="inline"></re-captcha>
                        <p class="required-warning" *ngIf="isFieldInvalid('captcha')">{{'REQUIRED_FIELD' | translate}}</p>
                    </div>
                </fieldset>

                <div style="margin-bottom: 5px;" *ngIf="!showIskRedirect">
                    * {{'REQUIRED_FIELD' | translate}}
                </div>

                <div style="margin-bottom: 50px;" *ngIf="!showIskRedirect">
                    <a *ngIf="selectedLanguage === 'nl'" href="/assets/attachments/231208_SW_privacyverklaring_nl.pdf" style="text-decoration: underline" target="_blank">Privacyverklaring</a>
                    <a *ngIf="selectedLanguage !== 'nl'" href="/assets/attachments/231208_SW_privacyverklaring_en.pdf" style="text-decoration: underline" target="_blank">Privacy statement</a>
                </div>

                <button type="submit" class="button-submit" style="margin-bottom: 100px" *ngIf="!showIskRedirect">{{'SEND' | translate}}</button>

                <section *ngIf="showIskRedirect" class="isk-redirect">
                    <p class="no-bottom-margin" [innerHTML]="'NEWCOMER_SIGNUP.ISK_REDIRECT_TEXT_1' | translate"></p>
                    <ul>
                        <li>
                            <a target="_blank" href="https://diamantcollege.nl/onze-school/isk-eoa/">https://diamantcollege.nl/onze-school/isk-eoa/</a>
                        </li>
                        <li>
                            <a target="_blank" href="https://edithsteincollege.nl/isk/">https://edithsteincollege.nl/isk/</a>
                        </li>
                        <li>
                            <a target="_blank" href="https://www.johandewittscholengroep.nl/informatie/aanmelden-isk">https://www.johandewittscholengroep.nl/informatie/aanmelden-isk</a>
                        </li>
                        <li>
                            <a target="_blank" href="https://www.zuidwestcollege.nl/onderwijs/eerste-opvang-anderstaligen/">https://www.zuidwestcollege.nl/onderwijs/eerste-opvang-anderstaligen/</a>
                        </li>
                    </ul>
                    <p [innerHTML]="'NEWCOMER_SIGNUP.ISK_REDIRECT_TEXT_2' | translate"></p>
                </section>
            </form>
        </div>
    </article>
</main>
