<main class="main-content" *ngIf="category">

  <div class="breadcrumbs-top">
    <a [routerLink]="['/']">Home</a>
    <span class="spacer"><i class="icon-right-small"></i></span>
    <a [routerLink]="['/', 'categorie', category.parent_category.name.toLowerCase()]">{{category.parent_category.name}}</a>
    <span class="spacer"><i class="icon-right-small"></i></span>
    <span>{{category.name}}</span>
  </div>


  <section class="section section-articles-intro">
    <div class="st-inner">
      <div class="container">
        <div class="row">

          <div class="col-sm-12 col-md-6 col-left">
            <div class="inner">

              <figure class="st-image bgimg has-before" [style.background-image]="'url(' + category.image + ')'">
                <img [src]="category.image" alt="Alternatieve tekst van de foto">
              </figure>

              <!--            <figure class="st-image bgimg has-before" data-bgimg="img/photos/middelbare-school.jpg" style="background-image: url(&quot;img/photos/middelbare-school.jpg&quot;);">-->
              <!--              <img src="img/photos/middelbare-school.jpg" alt="ALternatieve tekst">-->
              <!--            </figure>-->

            </div>
          </div>

          <div class="col-sm-12 col-md-6 col-right">
            <div class="inner">

              <div class="breadcrumbs-inline">
                <a [routerLink]="['/']">Home</a>
                <span class="spacer"><i class="icon-right-small"></i></span>
                <a [routerLink]="['/', 'categorie', category.parent_category.name.toLowerCase()]">{{category.parent_category.name}}</a>
                <span class="spacer"><i class="icon-right-small"></i></span>
                <span>{{category.name}}</span>
              </div>

              <div class="article-header">
                <h1 class="page-title h2">{{category.name}}</h1>
              </div>

              <div class="article-output">
                <p [innerHTML]="category.description"></p>
              </div>

              <br>

              <a href="#article-list" class="button" (click)="scrollTo('article-list', $event)">Naar artikelen <i class="icon-right-small"></i></a>
              <nav *ngIf="showFlags">
                <ul class="flag-list">
                  <li *ngFor="let country of SchoolChoiceLanguage | keyvalue" class="flag-list-item" [routerLink]="'/' + routeLink + country.value">
                    <img src="assets/img/school-choice/flag_{{country.value}}.png" alt="{{country.value}}">
                  </li>
                </ul>
              </nav>
            </div>
          </div>

        </div>
      </div>
    </div>
  </section>

  <section class="section section-article-grid">
    <div class="st-inner">
      <span class="before"></span>

      <div class="container">
        <div class="st-header indent">
          <h2 class="h2">Deze artikelen helpen u op weg</h2>
        </div>
        <div class="row" id="article-list">
          <a [routerLink]="['/', 'artikel', article.slug]" class="goto article-grid-item" *ngFor="let article of articles">
          <div class="col-xs-6 col-md-4 col-lg-3 col-item">

            <div class="item article c-article-box" style="cursor: pointer;">
              <div class="inner js-mh">
                <div class="article-image">
                  <figure class="bgimg"  [style.background-image]="'url(' + article.image + ')'">
                    <img [src]="article.image" alt="artikel plaatje">
                  </figure>
                  <i class="arrow icon-right-small"></i>
                </div>
                <h2 class="h4 title">
                  <span class=inside>{{article.title}} <i class="icon-right-small"></i></span>
                </h2>
              </div>
            </div>

          </div>
          </a>
        </div>
      </div>
    </div>
  </section>
</main>


